<template>
  <div class="contact">
    <div class="item">
      <div class="item-user">
        李先生    <span>商务总监</span>
      </div>
      <div class="item-info-box">
        <div class="item-info">
          <div class="item-info-sign">
            <div class="item-info-title">
              QQ
            </div>
            <div class="item-info-desc">
              54993464  
            </div>
          </div>
          <div class="item-info-sign">
            <!-- <div class="item-info-title">
              微信
            </div>
            <div class="item-info-desc">
              545067972  
            </div> -->
          </div>
        </div>

        <div class="item-qrcode">
          <img
            src="../img/qrcode.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <div class="item">
      <div class="item-user">
        郑雨琴    <span>媒介总监</span>
      </div>
      <div class="item-info-box">
        <div class="item-info">
          <div class="item-info-sign">
            <div class="item-info-title">
              QQ
            </div>
            <div class="item-info-desc">
              1300025177
            </div>
          </div>
          <div class="item-info-sign">
            <div class="item-info-title">
              微信
            </div>
            <div class="item-info-desc">
              15757153781
            </div>
          </div>
        </div>

        <div class="item-qrcode">
          <img
            src="../img/qrcode1.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <div class="item">
      <div
        class="item-user"
        style="color: #FF7800"
      >
        新量象公众号
      </div>
      <div class="item-info-box">
        <div class="item-info">
          <div class="item-info-sign">
            更多新量象资讯动态请关注 
          </div>
          <div class="item-info-sign">
            搜索微信公众号：新量象
          </div>
        </div>

        <div class="item-qrcode">
          <img
            src="../img/qrcode2.png"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.contact{
  display: flex;
  align-items: center;
  .item:nth-child(3){
    margin-right: 0;
  }
  .item{
    margin-right: 150px;
    &-user{
      color: rgba(102, 102, 102, 1);
      // font-family:'fzqk';
      font-size: 22px;
      line-height: 22px;
      margin-bottom: 30px;
      span{
        color:rgba(255,132,0,1);
        font-size: 14px;
        line-height: 22px;
      }
    }
    &-info-box{
      display: flex;
      align-items: flex-start;
    }
    &-info{
      margin-right: 77px;
      &-sign{
        display: flex;
        align-items: center;
        font-size:14px;
        color:rgba(153,153,153,1);
        line-height:14px;
        margin-bottom: 20px;
      }
      &-title{
        width: 40px;
        white-space: nowrap;
        margin-right: 16px;
      }
    }
    &-qrcode{
      width: 60px;
      height: 60px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .contact{
    display: flex;
    align-items: center;
    .item:nth-child(3){
      margin-right: 0;
    }
    .item{
      margin-right: 113px;
      &-user{
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 23px;
        span{
          font-size: 12px;
          line-height: 16px;
        }
      }
      &-info{
        margin-right: 50px;
        &-sign{
          font-size:12px;
          line-height:12px;
          margin-bottom: 16px;
        }
        &-title{
          width: 28px;
          margin-right: 10px;
        }
      }
      &-qrcode{
        width: 46px;
        height: 46px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>